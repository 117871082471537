/* LOAD GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;700;800;900&display=swap');

/* LOAD FONT AWESOME */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");





 /*** The new CSS Reset - version 1.3.1 (last updated 28.10.2021) ***/

 /*
     Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
     - The "symbol *" part is to solve Firefox SVG sprite bug
  */
 *:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
     all: unset;
     display: revert;
 }

 /* Preferred box-sizing value */
 *,
 *::before,
 *::after {
     box-sizing: border-box;
 }

 /* Remove list styles (bullets/numbers) */
 ol, ul {
     list-style: none;
 }

 /* For images to not be able to exceed their container */
 img {
     max-width: 100%;
 }

 /* removes spacing between cells in tables */
 table {
     border-collapse: collapse;
 }

 /* revert the 'white-space' property for textarea elements on Safari */
 textarea {
     white-space: revert;
 }
