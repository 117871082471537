html, body, #root, #root > div {
  min-height: 100vh;
}


body {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 16px;
  line-height: 1.8;
  cursor: default;
}

body:has(.navOpen),
body:has(.modalContainer) {
  overflow: hidden;
}

.globalContainer {
  background-color: #c3dedf;
  color: #3f3f3f;
}
.globalContainer.darkMode {
  background-color: #1a212c;
  color: #fff;
}

.globalContainer.darkMode h1,
.globalContainer.darkMode h2,
.globalContainer.darkMode h3,
.globalContainer.darkMode h4,
.globalContainer.darkMode p:not(li p) {
  color: #fff;
}

h1,h2,h3,h4,h5 {
  line-height: 1.2;
}

h1 {
  font-weight: 700;
  font-size: 3rem;
}
h2 {
  font-size: 1.875rem;
}
h3 {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
h4 {
  font-weight: 700;
  font-size: 1.5rem;
}


button.ctaDel {
  cursor: pointer;
  color: rgb(177, 177, 177);
}
button.ctaDel i {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
button.ctaDel i:before {
  z-index: 1;
  position: relative;
}
button.ctaDel i:after {
  content:"";
  background-color: rgb(177, 177, 177); 
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;left: 0;
  z-index: 0;
  display: inline-block;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.5s transform cubic-bezier(0.3,1.6,0.5,0.7);  
}

button.ctaDel span {
  display: inline-block;
  vertical-align: middle;
}


.smallCta span {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 800;
}

.cta.circle {
  padding: 0;
  height: 90px;
  width: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 1.5rem;
}

.ctaLink {
  font-weight: 800;
  display: inline-block;
  cursor: pointer;
}
.ctaLink:hover {
  text-decoration:underline;
}

.cta {
  padding: 18px 30px;
  font-weight: 800;
  font-size: 0.875rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #fa2059;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
}

.tac {
  text-align: center;
}

.vac {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dib {
  display: inline-block;
}


hr,
hr.wrap {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 80px;
  margin-bottom: 60px;
  display: block;
}

.darkMode hr,
.darkMode hr.wrap {
  background-color: rgba(255, 255, 255, 0.1);
}

.mt10 {
  margin-top: 8px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb50 {
  margin-bottom: 50px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}


.color-green {
  color: rgb(27, 226, 160);
}

strong {
  font-weight: 800;
}


.wrap {
  max-width: 80%;
  margin: 0 auto;
}

.wrap.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
@media screen and (max-width:1024px) {
  .wrap.flex {
    flex-direction: column;
  }
}


input, textarea {
  cursor: text;
}


input[type=email], input[type=datetime-local], input[type=date], input[type=number], input[type=password], input[type=text] {
  padding: 14px 0;
  border: 1px solid #dee0e8;
  border-radius: 10px;
  background-color: #FFF;
  color: #3f3f3f;
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
  max-width: 400px;
  width: 100%;
}
textarea {
  padding: 14px 20px;
  border: 1px solid #dee0e8;
  border-radius: 10px;
  background-color: #FFF;
  font-size: 16px;
  max-width: 400px;
  width: 100%;
}
input[type=datetime-local], input[type=date] {
  padding-right: 10px;
}

/* input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=text]:focus, textarea:focus {
  border-color: #dee0e8;
  border-radius: 6px;
} */



/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 14px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(51, 64, 85);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff;
  }


  @media screen and (max-width:1024px) {
   h1 {
    font-size: 2.25rem;
   }
  }