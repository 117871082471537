.modalContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;left: 0;
  z-index: 98;
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(219, 247, 248, 0.9);
  position: fixed;
  top: 0;left: 0;
  z-index: 0;
  opacity: 0;
  animation: fade 0.1s linear forwards;
  cursor: url('/./public/close.png'), auto;
}

body:has(.darkMode) .overlay {
  background-color: rgba(26, 33, 44, 0.95);
  cursor: url('/./public/close-w.png'), auto;
}


@keyframes fade {
  from{
    opacity: 0;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
  to{
    opacity: 1;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
}


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  display: inline-block;
  padding: 40px;
  width: 90%;
  max-width: 500px;
  color: #101012;
  z-index: 1;
  opacity: 0;
  animation: modalEntry 0.3s ease-out forwards;
}
.modal:before {
  content:"";
  position: absolute;
  z-index: -1;
  top: 0;left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

@keyframes modalEntry {
  0%{
    transform: translate(-50%, -45%);
    opacity: 0;
  }
  100%{
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}



.modalHeader {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
}

.modal .modal-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  display: inline-block;
  font-weight: 500;
  height: 40px;width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.modal .closeBtn:before {
  content:"";
  position: absolute;
  z-index: -1;
  top: 0;left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #F1F0F5;
  background: #fff;
  -webkit-transition: 0.2s all ease-out;
  -o-transition: 0.2s all ease-out;
  transition: 0.2s all ease-out;
}
.modal .closeBtn:hover:before {
  background: #F1F0F5;
}


.modal h4 {
  font-size: 30px;
  margin-bottom: 10px;
}

.modal input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}



.errorMsg {
  font-size: 14px;
  color: #e50f5c;
  margin: 20px 0;
}


legend {
  display: block;
  margin-bottom: 20px;
}





.formWrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}
.formWrap fieldset {
  display: inline-block;
  width: 50%;
}
.formWrap fieldset:first-of-type {
  margin-right: 40px;
  padding-right: 40px;
  border-right: 1px solid #dee0e8;
}

.warningMsg {
  margin-bottom: 20px;
}


.modal .mailSent i {
  font-size: 6rem;
  color: #53c3ae;
  margin-bottom: 20px;
}
.modal .mailSent p {
  font-size: 1.325rem;
  font-weight: 600;
  margin-bottom: 20px;
}