nav#globalHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 97;
  background-color: rgba(195, 222, 223, 0.8);
  backdrop-filter: blur(10px);
}

.darkMode nav#globalHeader {
  background-color: #1a212c;
}


nav#globalHeader a {
  display: inline-block;
  cursor: pointer;
}

nav#globalHeader li:not(.registerBtn) a:hover {
  color: #E01462;
}

nav#globalHeader .navWrap {
  width: 100%;
  position: relative;
  text-align: center;
}
nav#globalHeader ul li {
  background: #fff;
  border: 1px solid rgba(255,255,255,0.15);
  border-radius: 10px;
  font-weight: 900;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
  cursor: pointer;
  max-width: 400px;
  opacity: 0;
  transform:translateY(50px);
  transition: transform 0.2s ease-out 0.3s, opacity 0.1s ease-out 0.3s;
}
nav#globalHeader.navOpen ul li:nth-of-type(1) {
  transition-delay: 0s;
}
nav#globalHeader.navOpen ul li:nth-of-type(2) {
  transition-delay: 0.06s;
}
nav#globalHeader.navOpen ul li:nth-of-type(3) {
  transition-delay: 0.12s;
}
nav#globalHeader.navOpen ul li:nth-of-type(4) {
  transition-delay: 0.18s;
}
nav#globalHeader.navOpen ul li:nth-of-type(5) {
  transition-delay: 0.24s;
}
nav#globalHeader.navOpen ul li:nth-of-type(6) {
  transition-delay: 0.3s;
}
nav#globalHeader.navOpen ul li:nth-of-type(7) {
  transition-delay: 0.36s;
}

nav#globalHeader ul li.switchMenu {
  text-align: center;
  background: none;
  border: none;
  color: #fff;
  padding: 20px 0;
}

nav#globalHeader ul li:not(.registerBtn) a {
  display: block;
  padding: 20px;
}
nav#globalHeader ul li.loginBtn a {
  color: #fff;
}
nav#globalHeader ul li i:first-of-type {
  margin-right: 10px;
}

nav#globalHeader ul li i.chevron {
  position: absolute;
  right: 5%;
  top: 50%;
  font-size: 1rem;
  transform: translateY(-50%);
}

nav#globalHeader .logo {
  display: inline-block;
  position: relative;
  z-index: 99;
  margin-top: 20px;
}
.darkMode nav#globalHeader .logo {
  filter: brightness(10);
}

nav#globalHeader ul {
  background: #78c2c5;
  position: absolute;
  z-index: 2;
  top: 0;left: 0;
  height: 100vh;
  width: 100%;
  padding-top: 120px;
  overflow-y: auto;
}



nav#globalHeader .mobileMenu {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #78c2c5;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  top: 18px;right: 20px;
  cursor: pointer;
}

nav#globalHeader .mobileMenu span {
  display: inline-block;
  background: #fff;
  width: 40%;
  height: 3px;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 0;left: 0;right: 0;bottom: 0;
  margin: auto;
}

nav#globalHeader .mobileMenu span:after,
nav#globalHeader .mobileMenu span:before {
  content:"";
  display: inline-block;
  background: #fff;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  position: absolute;
  left: 0;
  -webkit-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}
nav#globalHeader .mobileMenu span:before {
  top: -8px;
}
nav#globalHeader .mobileMenu span:after {
  top: 8px;
}




/* OPEN & CLOSE STATES */


/* NAV CLOSED */
nav#globalHeader ul {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

/* NAV OPEN */
nav#globalHeader.navOpen ul {
  opacity: 1;
  transform: scale(1);
}

nav#globalHeader.navOpen ul li {
  transform: translateX(0);
  opacity: 1;
}

nav#globalHeader.navOpen ul li.loginBtn,
nav#globalHeader.navOpen ul li.registerBtn,
.darkMode nav#globalHeader.navOpen ul li.loginBtn,
.darkMode nav#globalHeader.navOpen ul li.registerBtn {
  border: none;
  text-align: center;
  background: none;
}

nav#globalHeader.navOpen .logo {
  filter: brightness(10);
}

nav#globalHeader.navOpen .mobileMenu {
  background-color: #fff;
}
nav#globalHeader.navOpen .mobileMenu span {
  background: transparent;
}
nav#globalHeader.navOpen .mobileMenu span:before,
nav#globalHeader.navOpen .mobileMenu span:after {
  top: 0;
  background-color: #78c2c5;
}
nav#globalHeader.navOpen .mobileMenu span:before {
  transform: rotate(45deg);
}
nav#globalHeader.navOpen .mobileMenu span:after {
  transform: rotate(-45deg);
}


.darkMode nav#globalHeader.navOpen .mobileMenu {
  background-color: #78c2c5;
}
.darkMode nav#globalHeader.navOpen .mobileMenu span:before,
.darkMode nav#globalHeader.navOpen .mobileMenu span:after {
  background-color: #fff;
}













/*DARK MODE*/

/*switcher element*/
.switchTheme {
  position: relative;
  height: 30px;
  overflow: hidden;
  width: 54px;
  border:2px solid #e6e6e6;
  border-radius: 50px;
  vertical-align: middle;
  display: inline-block;
  transition: border-color 0.2s ease 0.1s;
  cursor: pointer;
  margin: 0 10px;
}

.switchTheme span {
  display: inline-block;
  position: absolute;
  top: 3px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transition: 0.5s transform cubic-bezier(0.3,1.6,0.5,0.7) 0.05s;
}

.switchTheme span.dark {
  right: 5px;
  background-color: #fff;
  transform: translateX(calc((100% + 40px) * -1));
}

.switchTheme span.dark i {
  font-size: 10px;
  color: #fff;
}

.switchTheme span.light {
  left: 5px;
  background-color: #fff;
  transform: translateX(0px);
}

.switchLabel:last-of-type {
  opacity: 0.3;
}
/*end switcher element*/



/*switchTheme styles changes after click*/
.darkMode .switchTheme {
  border-color: rgba(255,255,255,0.2);
}

.darkMode .switchLabel:last-of-type {
  opacity: 1;
}
.darkMode .switchLabel:first-of-type {
  opacity: 0.3;
}
.darkMode .switchTheme span.dark {
  transform: translateX(0px);
}

.darkMode .switchTheme span.light {
  transform: translateX(calc(100% + 40px));
}

.darkMode nav#globalHeader ul {
  background: #161c25;
}
.darkMode nav#globalHeader ul li {
  background-color: rgba(255,255,255,0.02);
  color: #fff;
  border: 1px solid rgba(255,255,255,0.15);
}
nav#globalHeader ul li.switchMenu {
  background: none;
  border: 1px solid transparent;
}