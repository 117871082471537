main#MyRecords {
  padding-top: 120px;
}

main#MyRecords header {
  margin-bottom: 40px;
}

.recordList {
  max-width: 500px;
  margin: 0 auto;
  padding-right: 10px;
}

.MyRecords {
  padding-bottom: 100px;
}