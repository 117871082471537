.fzoneColor0{
    fill:rgba(0, 0, 0, 0.1);
}
.fzoneColor1{
    fill:rgba(255,255,255,0.5);
}
.fzoneColor2{
    fill:rgba(255, 255, 255, 0.05);
    cursor: pointer;
}
.fzoneColor2:hover {
    fill: rgba(255, 255, 255, 0.2);
}
.fzoneColor3{
    fill:none;
    stroke:rgba(255, 255, 255, 0.5);
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
    stroke-dasharray: 6px;
}

.fzoneColor2.selected {
    fill: #88f1eb;
}