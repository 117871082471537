.recordModal {
    display: inline-block;
    padding: 30px 20px;
    height: 100%;
    overflow: auto;
    width: 100%;
    background-color: #78c2c5;
    z-index: 1;
    opacity: 0;
    animation: recordModalEntry 0.3s ease-out forwards;
}
.recordModal.darkTheme {
  background-color: #1a212c;
}


.recordModal .selected-zone-name {
  display: inline-block;
  color: #fff;
  margin: 5px;
  padding: 6px 10px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.05);
  font-size: 0.825rem;
}


@keyframes recordModalEntry {
  0%{
    transform: translateY(50px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}




.recordModal .modal-close-button {
  position: absolute;
  top: 18px;right: 20px;
  z-index: 3;
  color: #78c2c5;
  background-color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 50px;width: 50px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 2rem;
}
.recordModal .closeBtn {
  position: absolute;
  top: 50%;left: 50%;
  transform:translate(-50%, -50%);
  margin-top: -2px;
  display: inline-block;
}





.recordModal legend {
  font-size: 1.25rem;
  font-weight: 800;
}

.recordModal legend,
.recordModal .label {
  color: #fff;
}


.recordModal fieldset {
  text-align: left;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.recordModal .zoneFieldset {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}

.labelDate {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.intensityRadioWrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.intensityInputWrap {
  display: inline-block;
  height: 60px;width: 60px;
  border-radius: 50%;
  position: relative;
}
.intensityInputWrap i {
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 50%;left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  pointer-events: none;
}
input.intensityRadio {
  position: absolute;
  top: 0;left: 0;
  z-index: 1;
  height: 100%;width: 100%;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.intensity01 {
  background-color:#1bf7ad;
}
.intensity02 {
  background-color:#88f1eb;
}
.intensity03 {
  background-color:#f3d324;
}
.intensity04 {
  background-color:#ff7519;
}
.intensity05 {
  background-color:#fa2059;
}

input.intensityRadio:checked {
  box-shadow: 0 0 0 15px rgba(0,0,0,0.2);
}

label input.intensityRadio:checked {
  opacity: 1;
}




/* .zonesWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; 
} */

#FrontZones,
#BackZones {
  display: inline-block;
  width: 47%;
  max-width: 400px;
}

#FrontZones {
  margin-right: 6%;
}



.zonesNav {
  background-color: rgba(255,255,255,0.05);
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 400px;
}

.zonesNav li {
  display: inline-block;
  width: calc(50% - 6px);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 6px 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: 600;
}
.zonesNav li:not(:last-of-type) {
  margin-right: 10px;
}

.zonesNav li.active {
  color: #fff;
  background-color: #78C2C5;
}


/* .darkTheme .zonesNav li {
  color: #fff;
} */

.zonesWrap > div {
  display: none;
}

.zonesWrap > div.active {
  display: block;
}


@media screen and (max-width:1024px) {
  #FrontZones,
  #BackZones {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
}








.recordModal .cta.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}


.recordModalError {
  color: #ffe244;
}