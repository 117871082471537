main#myProfile {
  padding-top: 120px;
}

main#myProfile h1 {
  margin-bottom: 20px;
}

main#myProfile input {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

main#myProfile input[type=email] {
  cursor: not-allowed;
}

.darkMode main#myProfile .ctaLink {
  color: #fff;
}


.modalInfo {
  position: fixed;
  z-index: 99;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #78C2C5;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 0 0 100vw rgb(0 0 0 / 40%);
}

.modalInfo i {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 20px;
}


/* DESKTOP & LANDSCAPE RESPONSIVE */

@media screen and (min-width: 1024px) and (orientation: landscape) {

  /* input[type=email], input[type=number], input[type=password], input[type=text], textarea {
    display: block;
    margin: 0 auto;
    min-width: 300px;
  } */

}
