main#MyDrugs {
  padding-top: 120px;
}

.drugList {
  margin: 0 auto;
}

.elemDrug {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  color: #3f3f3f;
  text-align: left;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.elemDrug.isArchived {
  opacity: 0.4;
}

.drugList {
  margin-bottom: 30px;
}