main#MyStatistic {
    padding-top: 120px;
    margin-bottom: 40px;
}
main#MyStatistic .wrap.col2 {
    text-align: center;
    margin-bottom: 40px;
}
#MyStatistic .happyHoola {
    max-width: 200px;
    margin-right: 30px;
    margin-left: 0;
    display: inline-block;
    vertical-align: middle;
}

main#MyStatistic  .mainText {
    max-width: 450px;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
}

main#MyStatistic h2 {
    font-weight: 700;
    font-size: 1.75rem;
    margin-bottom: 10px;
}



#MyStatistic label {
    display: block;
    margin-bottom: 10px;
}
.darkMode #MyStatistic label {
    color: #fff;
}
#MyStatistic input[type=date] {
    max-width: 200px;
    color: #3f3f3f;
}



.cardsWrap {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
}

.elemStatCard {
    background: #fff;
    color: #3f3f3f;
    border: 2px solid rgb(141, 141, 141);
    border-radius: 10px;
    padding: 30px 20px;
    display: inline-block;
    min-width: 220px;
    margin: 10px;
}
.elemStatCard .chartIcon {
    width: 60px;
}
.elemStatCard .statNum {
    font-size: 4rem;
}
.elemStatCard .statLabel {
    font-size: 0.875rem;
}

.elemStatCard  .statIcon {
    font-size: 4rem;
}







section.MyStatistic {
    padding-bottom: 120px;
    margin-top: 60px;
}


.CalendarHead {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 940px;
    margin-bottom: 10px;
}
.CalendarHead li {
    width: calc(100% / 7);
    display: inline-block;
}
.StatsCalendar {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 940px;
    background-color: rgba(255,255,255,1);
    border: 1px solid rgb(141, 141, 141);
    border-radius: 10px;
}
.StatsCalendar li {
    height: 114px;
    position: relative;
    width: calc(100% / 7);
    display: inline-block;
    border: 1px solid rgb(141, 141, 141);
    text-align: center;
    padding: 2px;
}

.StatsCalendar li:nth-of-type(1){
    border-radius: 10px 0 0 0;
}
.StatsCalendar li:nth-of-type(7){
    border-radius: 0 10px 0 0;
}
.StatsCalendar li:nth-of-type(36){
    border-radius: 0 0 0 10px;
}
.StatsCalendar li:nth-of-type(42){
    border-radius: 0 0 10px 0;
}


.StatsCalendar li .dateWrap {
    position: relative;
    z-index: 2;
    top: 50%;
    transform: translateY(calc(-50% - 4px));
}
.StatsCalendar li p {
    line-height: 1;
    word-break:break-word;
}

.StatsCalendar li p.dateDay {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.StatsCalendar li p.dateMMYY {
    font-size: 0.625rem;
    text-transform: uppercase;
}

.StatsCalendar li p.isDisable {
    opacity: 0.3;
}



.StatsCalendar .intensityCalendarWrap {
    position: absolute;
    top: 6px;right: 10px;
}
.StatsCalendar .intensityInputWrap {
    width: 10px;
    height: 10px;
    position: static;
}

.StatsCalendar .intensityInputWrap:not(:last-of-type) {
    margin-right: 6px; 
}




.darkMode .CalendarHead li {
    color: #fff;
}
.darkMode .StatsCalendar {
    color: #fff;
    background-color: rgba(255,255,255,0.05);
    border: 1px solid rgba(255,255,255,0.3);
}

.darkMode .StatsCalendar li {
    border:1px solid hsla(210, 12%, 87%, 0.3);
}



.MyStatistic h2 {
    line-height: 1.4;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.MyStatistic h2 span {
    font-size: 1.5em;
    display: block;
    margin-bottom: 10px;
}

section.MyStatistic > .wrap {
    background-color: #fff;
    border:2px solid rgba(255,255,255,1);
    border-radius: 10px;
    max-width: 1180px;
    width: 90%;
}

.darkMode section.MyStatistic > .wrap {
    background-color: #1A212C;
    border:2px solid rgba(255,255,255,1);
    border-radius: 10px;
    max-width: 1180px;
    width: 90%;
}

 section.MyStatistic .contentWrap {
    padding: 30px 10px 60px 10px;
 }

.MyStatistic .userName {
    background-color: #fff;
    color: #3f3f3f;
    display: block;
    padding: 30px;
    border-radius: 6px 6px 0 0;
    font-weight: 800;
    font-size: 0.875rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(141, 141, 141);
}


.chartZone {
    width: 100%;
    margin-bottom: 40px;
    padding: 30px 20px;
    text-align: center;
}
.chartZone ul {
    display: inline-block;
    vertical-align: top;
}
.chartZone ul li {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    position: relative;
}
.chartZone ul.keys {
    margin-right: 20px;
    width: 143px;
    text-align: right;
}
.chartZone ul.bars {
    width: calc(100% - 163px);
    max-width: 600px;
}
.chartZone ul.bars li {
    text-align: left;
    position: relative;
    padding-right: 20px;
    background-color: rgba(26, 33, 44, 0.2);
}

.darkMode .chartZone ul.bars li {
    background-color: rgba(255, 255, 255, 0.1);
}

.chartZone ul.bars li .bar {
    display: inline-block;
    vertical-align: top;
    background-color: #78C2C5;
    position: relative;
    height: calc(100% - 1px);
    margin-right: 10px;
}
.chartZone ul.bars li .value {
    display: inline-block;
    vertical-align: top;
}






@media screen and (max-width: 1024px) {
    section.MyStatistic {
        padding-bottom: 60px;
    }
    main#MyStatistic .wrap {
        display: block;
    }
    main#MyStatistic  .mainText {
        max-width: 450px;
        text-align: center;
        margin-top: 20px;
    }
    main#MyStatistic .wrap .mr20 {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .MyStatistic h2 {
        font-size: 1rem;
    }
    #MyStatistic .happyHoola {
        margin-right: 0;
    }
    section.MyStatistic .contentWrap {
        padding: 0px 10px 10px 10px;
    }
    .StatsCalendar li p.dateDay {
        font-size: 1.2rem;
        margin-bottom: 6px;
    }
    .StatsCalendar li {
        height: 80px;
    }
}
