.statRecordList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 940px;
  align-items: stretch;
  justify-content: flex-start;
  gap:10px;
}

.elemStatRecord .intensityInputWrap {
  height: 12px;
  width: 12px;
}

.elemStatRecord {
  display: inline-block;
  width: 30%;
  background: #fff;
  color: #3f3f3f;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 10px;
  margin: 0 1% 10px 1%;
  text-align: left;
  font-size: 0.875rem;
  position: relative;
  vertical-align: top;
}


.elemStatRecord header {
  position: relative;
  padding: 20px;
}
.elemStatRecord header p {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

/* .elemStatRecord p,
.elemStatRecord span {
  display: inline-block;
  vertical-align: middle;
} */

.elemStatRecord .lineLabel {
  margin-right: 5px;
  font-weight: 800;
}

.elemStatRecord .lineLabel.labelIntensity {
  display: none;
}

.elemStatRecord .elemContent {
  display: none;
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.3);
}

.elemStatRecord .elemContent.openTab {
  display: block;
}

.elemStatRecord .drugTag {
  font-size: 0.633rem;
  display: inline-block;
  padding: 4px 8px;
  background: rgba(26, 33, 44, 0.2);
  border-radius: 10px;
}
.elemStatRecord .drugTag:not(:last-of-type){
  margin-right: 8px;
}

.elemStatRecord .elemContent > *:not(:last-of-type) {
  margin-bottom: 10px;
}

.elemStatRecord .deleteRecord {
  text-align: right;
  margin-top: 10px;
  cursor: pointer;
}
.elemStatRecord .deleteRecord i,
.elemStatRecord .deleteRecord span {
  display: inline-block;
  vertical-align: middle;
}
.elemStatRecord .deleteRecord span {
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-left: 6px;
}

.elemStatRecord header  .openTab {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}


@media screen and (max-width:1024px) {
  .elemStatRecord {
    display: block;
    width: 100%;
  }
}