.ChangePasswordMain {
    padding-top: 150px;
}

.ChangePasswordMain h1 {
    font-size: 3rem;
}
.ChangePasswordMain h1 i {
    font-size: 5rem;
}
.ChangePasswordMain .wrap {
    max-width: 1024px;
    width: 75%;
    border-radius: 10px;
    padding: 80px 0;
}
.ChangePasswordMain .formWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}



/* RESPONSIVE */


@media screen and (max-width: 1024px) {

    .ChangePasswordMain .wrap {
        max-width: 90%;
        width: 90%;
        padding: 40px 20px;
    }

}