.modalCookie {
    position: fixed;
    z-index: 90;
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.02);
}
.modalCookie p {
    display: inline-block;
    margin-right: 20px;
}

.modalCookie p,
.modalCookie a {
    vertical-align: middle;
}

.modalCookie .smallCta {
    padding: 6px 14px;
    background-color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: pointer;
    display: inline-block;
}

.darkMode .modalCookie .smallCta {
    color: #1a212c;
}

.modalCookie .cookieContent {
    padding: 20px 0;
}


@media screen and (max-width: 1024px) {
    .modalCookie .smallCta {
        margin-top: 10px;
    }
}