#mainNotFound {
  min-height: 100%;
  padding: 10% 0;
}

#mainNotFound h1 {
  margin-bottom: 10px;
}

#mainNotFound .wrap > img {
  max-width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.NotFound{
  animation: glitch 0.8s linear infinite;
  margin: 0 auto;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.5rem;
}

@keyframes glitch{
  2%,64%{
    transform: translate(1px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-1px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg);
  }
}

.NotFound:before,
.NotFound:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.NotFound:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg);
  }
}

.NotFound:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg);
  }
}
