/* HOOLA SVG */


#HoolaRobot {
    max-width: 300px;
    width: 40%;
    display: inline-block;
    margin-bottom: 40px;
    vertical-align: middle;
    transform: rotate(-5deg);
    animation: rotateHoola 2.8s ease infinite alternate;
  }
  

  @keyframes rotateHoola {
    0%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(5deg);
    }
  }


.color2{fill:#FFFFFF;}
.color1{fill:#D7DDE9;}
.color3{fill:#78c2c5;}
.color4{fill:#3F3F3F;}





/* ANIMATIONS */

/* #HoolaRobot.hello .rightEye rect:nth-of-type(2) {
    animation: eyeA 1s ease infinite alternate;
}
#HoolaRobot.hello .rightEye rect:nth-of-type(3) {
    animation: eyeB 1s ease infinite alternate;
} */

/* #HoolaRobot.hello .leftEye rect:nth-of-type(2) {
    animation: eyeA 1s ease infinite;
}
#HoolaRobot.hello .leftEye rect:nth-of-type(3) {
    animation: eyeB 1s ease infinite;
} */

@keyframes eyeA {
    0%{
        transform: translate(0, 0);     
    }
    40%{
        transform: translate(1px, 13px);
    }
    60%, 100%{
        transform: translate(0, 0);
    }
}

@keyframes eyeB {
    0%{
        transform: translate(0, 0);     
    }
    30%{
        transform: translate(-1px, 13px);
    }
    60%, 100%{
        transform: translate(0, 0);
    }
}


#HoolaRobot.hello .rightArm {
    transform: translate(5px, -6px) rotate(1deg);
    animation: armRight 1.4s ease infinite;
}

@keyframes armRight {
    0% {
        transform: translate(5px, -6px) rotate(1deg);
    }
    30% {
        transform: translate(5px, -6px) rotate(-3deg);
    }
    60%, 100% {
        transform: translate(5px, -6px) rotate(1deg);
    }
}


#HoolaRobot.hello .leftArm {
    transform: rotate(1deg);
    animation: armLeft 1.4s ease infinite;
}

@keyframes armLeft {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: translateY(-6px) rotate(2deg);
    }
    60%, 100% {
        transform: rotate(0deg);
    }
}
