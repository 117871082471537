
.basicPage main {
    padding-top: 150px;
}

.basicPage h2 {
    margin-bottom: 10px;
}

.basicPage .wrap {
    max-width: 850px;
    width: 85%;
    margin: 0 auto;
}

.basicPage .interSection {
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.basicPage .interSection:not(:last-of-type) {
    border-bottom: 2px solid rgba(255,255,255,0.1);
}