.drugInput {
  background: rgba(0,0,0,0.1);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.drugInput p {
  width: calc(100% - 140px);
  word-break: break-all;
}
.drugInput input.form-control {
  border: none;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #78C2C5;
}
.drugInput .btnCounter {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.input-group {
  background: rgba(255,255,255,0.2);
  border-radius: 50px;
}

.drugInput input.form-control {
  padding-left: 14px;
}


@media screen and (max-width:1024px) {
  .drugInput input.form-control {
    padding-left: 0;
  }
}