.PageAbout main {
    padding-top: 150px;
}
.PageAbout main .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.PageAbout main .wrap .content {
    max-width: 500px;
}

.PageAbout section {
    margin-bottom: 40px;
    padding-bottom: 40px;
}

section.about01 {
    padding: 80px 0;
}
section.about01 .content {
    min-width: 350px;
}

section.about01 .content li p {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 60px);
}
section.about01 .content li span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: center;
    margin-right: 10px;
    height: 50px;
    width: 50px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    background: rgba(255,255,255,0.05);
}
section.about01 .content li span i {
    position: absolute;
    top: 50%;left: 50%;
    transform: translate(-50%, -50%);
}
section.about01 img {
    max-width: 500px;
    width: 90%;
}

  

section.aboutWhy .wrap div {
    max-width: 500px;
}
section.aboutWhy .flex {
    gap: 80px;
    align-items: flex-start;
}

section.aboutWhy .quote {
    padding: 20px 0;
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
    font-size: 1.5rem;
}
section.aboutWhy .quote i {
    display: block;
    font-size: 3rem;
}
section.aboutWhy .quote i.brain {
    font-size: 4rem;
    margin-bottom: -30px;
}
  
section.justDoIt {
    margin-bottom: 60px;
}
section.justDoIt img {
    max-width: 100px;
    margin-right: 20px;
}
section.justDoIt img,
section.justDoIt p {
    display: inline-block;
    vertical-align: middle;
}
section.justDoIt p {
    max-width: 280px;
    text-align: left;
}

@media screen and (max-width:1024px) {
    .PageAbout main .wrap {
        display: block;
    }
    .PageAbout main {
        text-align: center;
    }
    .PageAbout main .content {
        margin: 0 auto;
    }
    section.aboutWhy .wrap div {
        margin: 0 auto;
    }
}