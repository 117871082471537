#globalFooter {
    margin-top: 40px;
    position: relative;
    z-index: 1;
}
#globalFooter .wrap {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding: 40px 0 10px 0;
}
.darkMode #globalFooter .wrap {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
}
#globalFooter .wrap > img {
    margin-right: 30px;
}
#globalFooter li a:not(:last-of-type) img {
    margin-right: 30px;
}
#globalFooter .wrap ul, 
#globalFooter img {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
}
#globalFooter .wrap ul li{
    display: inline-block;
}
#globalFooter .wrap ul li a {
    display: inline-block;
    cursor: pointer;
}
#globalFooter .wrap ul li a i {
    font-size: 1.5rem;
}
#globalFooter .wrap ul li:not(:last-of-type) {
    margin-right: 40px;
}

.smallText {
    padding-top: 30px;
    font-size: 15px;
    color: #9d9d9d;
    text-align: center;
}

.subFooter {
    font-size: 0.722rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.2;
    padding: 20px 0;
}


@media screen and (max-width: 1024px) {
    #globalFooter .wrap ul li {
        display: block;
        margin-top: 20px;
    }
    #globalFooter .wrap ul li:not(:last-of-type) {
        margin-right: auto;
    }
    #globalFooter .wrap ul li.social {
        display: inline-block;
    }
    #globalFooter .wrap ul li.social a {
        padding: 8px;
    }
    #globalFooter .wrap ul li.social:not(:last-of-type) {
        margin-right: 10px;
    }
}