.bzoneColor0{
    fill:rgba(255, 255, 255, 0.05);
    cursor: pointer;
}
.bzoneColor0:hover {
    fill: rgba(255, 255, 255, 0.2);
}
.bzoneColor1{
    fill:rgba(255, 255, 255, 0.5);
}
.bzoneColor2{
    fill:none;
    stroke:rgba(255, 255, 255, 0.5);
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
    stroke-dasharray: 6px;
}