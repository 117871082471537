
.elemRecord .intensityInputWrap {
    height: 12px;
    width: 12px;
}

.elemRecord {
    width: 100%;
    background: #fff;
    color: #3f3f3f;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 0.875rem;
    position: relative;
  }
  
  .elemRecord header {
    position: relative;
    cursor: pointer;
    padding: 20px;
  }
  .elemRecord header p {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
  }
  
  .elemRecord p,
  .elemRecord span {
    display: inline-block;
    vertical-align: middle;
  }
  
  .elemRecord span.lineLabel {
    margin-right: 10px;
    font-weight: 800;
  }
  
  .elemRecord span.lineLabel.labelIntensity {
    display: none;
  }
  
  .elemRecord .elemContent {
    display: none;
    padding: 20px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  
  .elemRecord .elemContent.openTab {
    display: block;
  }
  
  .elemRecord .drugTag {
    font-size: 0.633rem;
    display: inline-block;
    padding: 4px 8px;
    background: rgba(26, 33, 44, 0.2);
    border-radius: 10px;
  }
  .elemRecord .drugTag:not(:last-of-type){
    margin-right: 8px;
  }
  
  .elemRecord .elemContent > *:not(:last-of-type) {
    margin-bottom: 10px;
  }
  
  .elemRecord .deleteRecord {
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
  }
  .elemRecord .deleteRecord i,
  .elemRecord .deleteRecord span {
    display: inline-block;
    vertical-align: middle;
  }
  .elemRecord .deleteRecord span {
    text-transform: uppercase;
    font-size: 0.875rem;
    margin-left: 6px;
  }
  
  .elemRecord header  .openTab {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  